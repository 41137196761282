.AddSubject {
    &-NewAdd {
        &-SubjectPicture {
            &-Upload {
                &-Explanation {
                    height: 12px;
                    font-family: "Rubik", sans-serif;
                    font-size: 10px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.4;
                    letter-spacing: normal;
                    text-align: left;
                    color: #405d72;
                    margin: 0px;

                    @media (max-width: 959px) {
                        width: 100%;
                        margin-left: 0px;
                        line-height: 1.2;
                    }
                }

                &-IconText {
                    height: 12px;
                    font-family: "Rubik", sans-serif;
                    font-size: 10px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.4;
                    letter-spacing: normal;
                    text-align: center;
                    color: #6296fc;
                }
            }

        }

        &-Lessons {
            margin-left: 45px;
            margin-top: 10px;

            @media (max-width: 959px) {
                margin: 0px;
                margin-top: 40px;
            }

            &-Input {

                width: 485px;
                height: 39px;
                border: solid 1px #c9c9c9;
                background-color: #ffffff;
            }

            &-Explanation {
                height: 12px;
                font-family: "Rubik", sans-serif;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.4;
                letter-spacing: normal;
                text-align: left;
                color: #405d72;
                margin: 0px;
            }
        }

        &-Subject {
            margin-left: 45px;
            margin-top: 10px;

            @media (max-width: 959px) {
                margin: 0px;
            }

            &-Input {

                width: 485px;
                height: 39px;
                border: solid 1px #c9c9c9;
                background-color: #ffffff;
            }

            &-Explanation {
                height: 12px;
                font-family: "Rubik", sans-serif;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.4;
                letter-spacing: normal;
                text-align: left;
                color: #405d72;
                margin: 0px;
            }
        }

        &-Title {
            margin-left: 40px;
            margin-top: 10px;

            @media (max-width: 959px) {
                margin: 0px;
            }

            &-Input {

                width: 485px;
                height: 39px;
                border: solid 1px #c9c9c9;
                background-color: #ffffff;
            }

            &-Explanation {
                height: 12px;
                font-family: "Rubik", sans-serif;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.4;
                letter-spacing: normal;
                text-align: left;
                color: #405d72;
                margin: 0px;
            }
        }

        &-Contents {
            margin-left: 40px;
            margin-top: 10px;

            @media (max-width: 959px) {
                margin: 0px;
            }

            &-Input {

                width: 485px;
                height: 162px;
                border: solid 1px #c9c9c9;
                background-color: #ffffff;


                @media (max-width: 959px) {
                    width: 100% !important;
                    margin-left: 0px;
                }
            }

            &-Explanation {
                height: 12px;
                font-family: "Rubik", sans-serif;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.4;
                letter-spacing: normal;
                text-align: left;
                color: #405d72;
                margin: 0px;
            }
        }

        &-Type {
            margin-left: 55px;
            margin-top: 10px;

            @media (max-width: 959px) {
                margin: 0px;
            }

            &-Input {

                width: 485px;
                height: 39px;
                border: solid 1px #c9c9c9;
                background-color: #ffffff;
            }

            &-Explanation {
                height: 12px;
                font-family: "Rubik", sans-serif;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.4;
                letter-spacing: normal;
                text-align: left;
                color: #405d72;
                margin: 0px;
            }
        }

        &-Button {
            margin-left: 440px;

            @media (max-width: 959px) {
                text-align: center;
                margin-left: 0px;
            }

            &-Style {
                width: 143px;
                height: 49px;

                border-radius: 7px;
                background-color: #0066ff;
                border: none;
            }

            &-Text {
                height: 20px;
                font-family: "Nunito", sans-serif;
                font-size: 15px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: #fafafa;
            }
        }
    }


    &-CurrentSubject {
        display: flex;
        flex-wrap: wrap;

        &-Card {
            width: 494px;
            background-color: #ffffff;
            border-radius: 10px;
            margin: 0px 50px 50px 0;

            @media (max-width: 959px) {
                width: 100%;
                margin: 0px 0px 50px 0;
            }

        }

        &-Image {
            width: 494px;
            height: 196px;
            border-radius: 10px 10px 0 0;

            @media (max-width: 959px) {
                width: 350px;
            }
        }

        &-LessonDate {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-LessonName {
            height: 26px;
            font-family: "Nunito", sans-serif;
            font-size: 19px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.37;
            letter-spacing: normal;
            text-align: left;
            color: #434655;
            padding: 0px 10px;
        }

        &-Date {

            display: flex;
            align-items: center;


            &-Icon {
                width: 13.9px;
                height: 13.9px;
                margin-right: 6px;
                color: #a8aabc;
            }


            &-Text {
                margin-top: 6px;
                height: 15px;
                font-family: "Nunito", sans-serif;
                font-size: 11px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: -0.12px;
                text-align: center;
                color: #a8aabc;
                margin-right: 10px;
            }
        }

        &-Subject {
            height: 20px;
            font-family: "Nunito", sans-serif;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #a8aabc;
            padding: 0px 10px;
        }

        &-Section {
            width: 104px;
            height: 32px;
            border-radius: 5px;
            background-color: #c2d5f9;
            margin-left: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            align-items: center;
            display: flex;
            justify-content: center;

            &-Text {
                height: 15px;
                font-family: "Nunito", sans-serif;
                font-size: 11px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                color: #447ff4;

            }
        }
    }

    .ant-upload-drag {
        width: 485px !important;
        height: 100% !important;
        border: solid 1px #c9c9c9 !important;
        background-color: #ffffff !important;

        @media (max-width: 959px) {
            width: 100% !important;
            height: 100% !important;
            margin-left: 0px;
        }
    }

    .ant-upload-text {
        height: 14px;
        font-family: "Nunito", sans-serif !important;
        font-size: 10px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.4 !important;
        letter-spacing: normal !important;
        text-align: center !important;
        color: #434655 !important;
    }

    .ant-select-in-form-item {
        width: 485px !important;
        height: 39px !important;
        border: solid 1px #c9c9c9 !important;
        background-color: #ffffff !important;
        border-radius: 8px !important;

        @media (max-width: 959px) {
            width: 100% !important;
            margin-left: 0px;
        }

    }



    .ant-upload-list {
        width: 485px !important;
    }



    .ant-tabs-tab {
        margin: 0px !important;
    }

    .ant-tabs-ink-bar {
        background-color: transparent;
    }

    .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            width: 300px;
            height: 60px;
            border-radius: 10px;
            border: solid 1px #e5e5ea;
            background-color: #fff;

            font-family: "Nunito", sans-serif;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.35;
            letter-spacing: -0.22px;
            color: #434655 !important;

            @media (max-width: 959px) {
                width: 180px;
                font-size: 16px;
                margin: 0px 10px;
            }
        }
    }

    .ant-tabs-tab-btn {
        border-radius: 10px;
        width: 300px;
        height: 60px;
        background-color: #efeff4;
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.22px;
        text-align: center !important;
        color: #a8aabc !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        @media (max-width: 959px) {
            width: 180px;
            font-size: 16px;
        }
    }

}