@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

@media (max-width: 959px) {
  .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }

  .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-next-btn,
  .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .ant-picker-presets {
    display: none !important;
  }
}

@import "../../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "./core/mixins.scss";
@import "./core/functions.scss";
@import "./core/_type.scss";
@import "./core/variables.scss";
@import "./shared/companent/_button.scss";
@import "./shared/companent/_dataTable.scss";
@import "./shared/shared.scss";