@import "../../../styles/_main.scss";

.wrap {
  margin-top: toRem(70);
  height: 100%;
  padding: toRem(20) toRem(50);
  transition: all 0.25s;
  background-color: #f9fafa;

  @media (max-width: 959px) {
    padding: toRem(20) toRem(25);
  }

  &.active {
    margin-left: toRem(290);
  }
}