.Story {

    &-BannerArea {

        &-Title {
            height: 32px;
            margin: 20px 0px 45px;
            font-family: "Nunito", sans-serif;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #434655;
        }

        &-BannerText {
            &-Input {
                width: 485px;
                height: 105.3px;
                border: solid 1px #c9c9c9;
                background-color: #fff;

                @media (max-width: 959px) {
                    width: 100%;
                }

                &-Explanation {
                    height: 12px;
                    font-family: "Rubik", sans-serif;
                    font-size: 10px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.4;
                    letter-spacing: normal;
                    text-align: left;
                    color: #405d72;
                }
            }
        }

        &-BannerPicture {
            margin-left: 0px;

            &-Upload {

                @media (max-width: 959px) {
                    width: 100%;
                }


                &-Explanation {
                    height: 12px;
                    font-family: "Rubik", sans-serif;
                    font-size: 10px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.4;
                    letter-spacing: normal;
                    text-align: left;
                    color: #405d72;

                    @media (max-width: 959px) {
                        line-height: 1.1;
                        margin-top: 2px;
                    }
                }

                &-IconText {
                    height: 12px;
                    font-family: "Rubik", sans-serif;
                    font-size: 10px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.4;
                    letter-spacing: normal;
                    text-align: center;
                    color: #6296fc;
                }
            }
        }

        &-Application {

            margin-left: 25px;

            @media (max-width: 959px) {
                margin-left: 0px;
            }

            &-Input {

                width: 485px;
                height: 44.4px;
                border: solid 1px #c9c9c9;
                background-color: #ffffff;

                @media (max-width: 959px) {
                    width: 100%;
                }
            }

            &-Explanation {
                height: 12px;
                font-family: "Rubik", sans-serif;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.4;
                letter-spacing: normal;
                text-align: left;
                color: #405d72;
            }
        }

        &-Button {
            width: 143px;
            height: 49px;
            margin-left: 450px;
            border-radius: 7px;
            background-color: #0066ff;
            border: none;

            @media (max-width: 959px) {
                margin-left: 125px;
            }

            &-Text {
                height: 20px;
                font-family: "Nunito", sans-serif;
                font-size: 15px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: #fafafa;
            }
        }
    }

    &-StoryArea {


        &-Title {
            height: 32px;
            margin: 20px 0px 45px;
            font-family: "Nunito", sans-serif;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #434655;
        }


        &-StoryPicture {
            margin-left: 15px;

            @media (max-width: 959px) {
                margin-left: 0px;
            }

            &-Upload {


                &-Explanation {
                    height: 12px;
                    font-family: "Rubik", sans-serif;
                    font-size: 10px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.4;
                    letter-spacing: normal;
                    text-align: left;
                    color: #405d72;

                    @media (max-width: 959px) {
                        line-height: 1.4;
                        margin-bottom: 2px;
                    }

                    &-Application {

                        margin-left: 25px;

                        @media (max-width: 959px) {
                            margin-left: 0px;
                        }

                        &-Input {

                            width: 485px;
                            height: 44.4px;
                            border: solid 1px #c9c9c9;
                            background-color: #ffffff;

                            @media (max-width: 959px) {
                                width: 100%;
                            }
                        }

                        &-Explanation {
                            height: 12px;
                            font-family: "Rubik", sans-serif;
                            font-size: 10px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 2.4;
                            letter-spacing: normal;
                            text-align: left;
                            color: #405d72;


                        }
                    }
                }

                &-IconText {
                    height: 12px;
                    font-family: "Rubik", sans-serif;
                    font-size: 10px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.4;
                    letter-spacing: normal;
                    text-align: center;
                    color: #6296fc;
                }
            }
        }

        &-Application {

            margin-left: 20px;

            @media (max-width: 959px) {
                margin-left: 0px;
            }

            &-Input {

                width: 485px;
                height: 44.4px;
                border: solid 1px #c9c9c9;
                background-color: #ffffff;
            }

            &-Explanation {
                height: 12px;
                font-family: "Rubik", sans-serif;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.4;
                letter-spacing: normal;
                text-align: left;
                color: #405d72;
            }

        }

        &-Button {
            width: 143px;
            height: 49px;
            margin-left: 450px;
            border-radius: 7px;
            background-color: #0066ff;
            border: none;

            @media (max-width: 959px) {
                margin-left: 125px;
            }

            &-Text {
                height: 20px;
                font-family: "Nunito", sans-serif;
                font-size: 15px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: #fafafa;
            }
        }
    }

    .ant-upload-drag {
        width: 485px !important;
        height: 162px !important;
        border: solid 1px #c9c9c9 !important;
        background-color: #ffffff !important;

        @media (max-width: 959px) {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .ant-upload-text {
        height: 14px;
        font-family: "Nunito", sans-serif !important;
        font-size: 10px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.4 !important;
        letter-spacing: normal !important;
        text-align: center !important;
        color: #434655 !important;
    }

    .ant-select-in-form-item {
        width: 485px !important;
        height: 44.4px !important;
        border: solid 1px #c9c9c9 !important;
        background-color: #ffffff !important;
        border-radius: 8px !important;


        @media (max-width: 959px) {
            width: 100% !important;
        }

    }

    .ant-upload-list {
        width: 485px !important;
    }

    .ant-form-item-label {
        margin-right: 200px;
    }
}