.AllQuestion {

    &-LessonName {
        text-align: left !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
    }

    &-QuestionButton {
        width: 130px;
        height: 45px;
        border-radius: 7px;
        background-color: #447ff4;
        text-decoration: none;
        border: none;



        &-Text {
            font-family: "Nunito", sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
        }
    }

}

.ant-table-cell {
    align-items: center !important;
    text-align: center !important;
    vertical-align: middle !important;
    background-color: transparent !important;
}

.ant-table-row {
    background-color: #f9fafa !important;
}


.ant-pagination-item {
    a {
        color: #447ff4 !important;
    }

    &-active {
        background-color: #447ff4 !important;

        a {
            color: #ffffff !important;
        }
    }
}



.ant-pagination-item-link {
    svg {
        color: #447ff4;
    }
}

.ant-table-thead {
    ::before {
        background-color: transparent !important;
    }
}

.ant-table-tbody {
    tr {
        td {
            border-bottom: 0px !important;
        }
    }
}