.btn {
  font-family: "Nunito", sans-serif;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 25px;
  border-radius: 7px;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  cursor: pointer;
  border: 1px solid transparent;

  &-primary {
    background-color: $primary;
    border-color: $primary;
    color: white;
    &:hover {
      background-color: #204285;
      border-color: #204285;
    }
    :focus {
      background-color: black;
    }
  }
}
