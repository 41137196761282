.TestSubject {
    &-FirstArea {

        &-ButtonArea {
            display: flex;
            justify-content: right;
            margin-bottom: 20px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
            }

            &-Button {


                &-InformationCardAdd {
                    width: 144px;
                    height: 43px;
                    border-radius: 10px;
                    background-color: #007bff;
                    border: none;
                    margin: 10px;

                    @media (max-width: 959px) {
                        width: auto;
                        padding: 0px 5px;
                    }

                }

                &-SubjectCardAdd {
                    width: 144px;
                    height: 43px;
                    border-radius: 10px;
                    background-color: #007bff;
                    border: none;
                    margin: 10px;

                    @media (max-width: 959px) {
                        width: auto;
                        padding: 0px 5px;
                    }
                }

                &-TestAdd {
                    width: 127px;
                    height: 43px;
                    border-radius: 10px;
                    background-color: #007bff;
                    border: none;
                    margin: 10px;

                    @media (max-width: 959px) {
                        width: auto;
                        padding: 0px 5px;
                    }
                }

                &-History {
                    width: 127px;
                    height: 43px;
                    border-radius: 10px;
                    background-color: #007bff;
                    border: none;
                    margin: 10px;

                    @media (max-width: 959px) {
                        width: auto;
                        padding: 0px 5px;
                    }

                }

                &-Text {
                    font-family: "Nunito", sans-serif;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;


                    @media (max-width: 959px) {
                        line-height: 1.2;
                    }
                }



            }
        }

    }


    &-SecondArea {

        &-List {

            &-DetailButton {
                width: 130px;
                height: 45px;
                border-radius: 7px;
                background-color: #06f;
                border: none;

                &-Text {
                    height: 19px;
                    font-family: "Roboto", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fafafa;
                }

            }
        }
    }


    .ant-table-cell {
        background-color: #f9fafa !important;
        align-items: center !important;
        text-align: center !important;
        vertical-align: middle !important;
        background-color: transparent !important;
        font-family: "Nunito", sans-serif !important;
        font-size: 18px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.35 !important;
        letter-spacing: normal !important;
        color: #434655 !important;
    }

    .ant-table-row {
        background-color: #f9fafa !important;
        height: 11px !important;
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #434655 !important;
    }



    .CustomPlaceholder .ant-select-selection-item {
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #a8aabc;
    }

    .CustomPlaceholder::placeholder {
        text-align: center;
        color: #434655;
    }

    .ant-table-thead {
        ::before {
            background-color: transparent !important;

        }

        background-color: #f9fafa !important
    }



    .ant-table-tbody {
        tr {
            td {
                border-bottom: 0px !important;
                color: #434655 !important;
                font-size: 18px !important;
                font-weight: normal !important;

            }
        }
    }


}