.JobDetail {
    display: grid;

    &-FirstArea {


        &-Style {
            width: 1077px;
            border-radius: 10px;
            background-color: #ffffff;
            padding: 10px;
            height: auto;
            margin-bottom: 40px;
        }

        &-Heading {
            height: 26px;
            font-family: "Nunito", sans-serif;
            font-size: 19px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.37;
            letter-spacing: normal;
            text-align: left;
            color: #434655;
            margin-left: 5px;
        }

        &-Line {
            display: flex;
            justify-content: left;
            margin: 5px;

            &-Title {

                height: 26px;
                font-family: "Nunito", sans-serif;
                font-size: 19px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.37;
                letter-spacing: normal;
                text-align: left;
                color: #a8aabc;
            }

            &-Text {
                height: 26px;
                font-family: "Nunito", sans-serif;
                font-size: 19px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.37;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
                margin-left: 5px;
            }

        }
    }

    &-SecondArea {

        &-Style {
            width: 1077px;
            border-radius: 10px;
            background-color: #ffffff;
            padding: 10px;
            margin-bottom: 40px;
            height: auto;
        }

        &-Heading {
            height: 26px;
            font-family: "Nunito", sans-serif;
            font-size: 19px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.37;
            letter-spacing: normal;
            text-align: left;
            color: #434655;
            margin-left: 5px;
        }

        &-Line {
            display: flex;
            margin: 5px;

            &-Title {
                width: auto;
                height: 26px;
                font-family: "Nunito", sans-serif;
                font-size: 19px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.37;
                letter-spacing: normal;
                text-align: left;
                color: #a8aabc;
                margin-right: 5px;
            }

            &-Text {

                font-family: "Nunito", sans-serif;
                font-size: 19px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.37;
                letter-spacing: normal;
                text-align: left;
                color: #434655;

            }

        }
    }

    &-ButtonArea {
        display: flex;

        &-Button {


            &-Approve {
                width: 188px;
                height: 65px;
                border-radius: 7px;
                background-color: #0066ff;
                margin-right: 20px;
                border: none;

                &-Text {
                    height: 27px;
                    font-family: "Nunito", sans-serif;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.35;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fafafa;
                }
            }

            &-Reject {
                width: 188px;
                height: 65px;
                border-radius: 7px;
                background-color: #ff1818;
                border: none;


                &-Text {
                    height: 27px;
                    font-family: "Nunito", sans-serif;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.35;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fafafa;
                }

            }
        }
    }

}