.StudentsDetails {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 959px) {
        flex-wrap: wrap;

    }

    &-FirstArea {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        @media (max-width: 959px) {
            flex-wrap: wrap;

        }


        &-StudentInformation {
            width: 600px;
            height: 404px;
            border-radius: 24px;
            background-color: #ffffff;
            padding-top: 20px;
            margin-right: 10px;

            @media (max-width: 959px) {
                width: 100%;
                height: 100%;
            }

            &-Text {
                margin: 20px 0px 0px 130px;
                height: 32px;
                font-family: "Nunito", sans-serif;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
                align-items: center;

                @media (max-width: 959px) {
                    margin: 20px 0px 0px 30px;
                    font-size: 12px;
                }
            }

        }


        &-ActivePackages {
            width: 600px;
            height: 404px;
            border-radius: 24px;
            background-color: #ffffff;
            padding-top: 20px;

            @media (max-width: 959px) {
                width: 100%;
                height: 100%;
                margin-top: 25px;
            }



            &-Title {
                height: 32px;
                font-family: "Nunito", sans-serif;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: #434655;

                @media (max-width: 959px) {
                    font-size: 20px;
                }
            }

            &-Comment {
                display: flex;
                justify-content: space-between;



                &-Left {
                    height: 26px;
                    font-family: "Nunito", sans-serif;
                    font-size: 19px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.37;
                    letter-spacing: normal;
                    text-align: left;
                    color: #434655;
                    margin: 2px;
                    padding: 0 10px;

                    @media (max-width: 959px) {
                        font-size: 10px;
                    }

                    &-Explanation {
                        height: 16px;
                        font-family: "Nunito", sans-serif;
                        font-size: 12px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                        margin-bottom: 10px;
                        margin-left: 10px;
                    }
                }

                &-Right {
                    height: 27px;
                    font-family: "Nunito", sans-serif;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.35;
                    letter-spacing: normal;
                    text-align: right;
                    color: #434655;
                    margin: 1px;
                    padding: 0 10px;

                    @media (max-width: 959px) {
                        font-size: 10px;
                    }
                }
            }
        }

    }



    &-SecondArea {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 959px) {
            flex-wrap: wrap;

        }

        width: 100%;

        &-ButtonArea {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 959px) {
                flex-wrap: wrap;

            }

            &-SelectInput {
                width: 150px;
                height: 60px;
                border-radius: 10px;
                border: solid 2px #434655;
                background-color: #ffffff;
                font-family: "Nunito", sans-serif;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                margin: 14px 5px;

                @media (max-width: 959px) {
                    height: 40px;
                    font-size: 12px;
                }
            }

            &-Add {
                width: 150px;
                height: 60px;
                border-radius: 10px;
                background-color: #45bf55;
                margin: 14px 5px;
                border: none;

                @media (max-width: 959px) {
                    height: 40px;
                }

            }

            &-Block {
                width: 150px;
                height: 60px;
                border-radius: 10px;
                background-color: #ff9200;
                margin: 14px 5px;
                border: none;

                @media (max-width: 959px) {
                    height: 40px;
                }
            }

            &-Delete {
                width: 150px;
                height: 60px;
                border-radius: 10px;
                background-color: #ff0000;
                margin: 14px 5px;
                border: none;

                @media (max-width: 959px) {
                    height: 40px;
                }
            }

            &-ReportedQuestions {
                width: 150px;
                height: 60px;
                border-radius: 10px;
                background-color: #0066ff;
                margin: 14px 5px;
                border: none;
                display: flex;
                align-items: center;
                text-decoration: none;

                @media (max-width: 959px) {
                    height: 40px;
                    justify-content: center;
                }
            }

            &-ReportedSolutions {
                width: 150px;
                height: 60px;
                border-radius: 10px;
                background-color: #0066ff;
                margin: 14px 5px;
                border: none;
                display: flex;
                align-items: center;
                text-decoration: none;

                @media (max-width: 959px) {
                    height: 40px;
                    justify-content: center;
                }
            }

            &-ButtonText {
                font-family: "Nunito", sans-serif;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: #fafafa;


                @media (max-width: 959px) {
                    font-size: 12px;
                }

            }

        }

        &-History {
            width: 775px;
            height: 404px;
            border-radius: 24px;
            background-color: #ffffff;

            @media (max-width: 959px) {
                width: 100%;
            }


        }


    }



    .ant-table-cell {
        align-items: center !important;
        text-align: center !important;
        vertical-align: middle !important;
        background-color: transparent !important;
        font-family: "Nunito", sans-serif !important;
        font-size: 18px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.35 !important;
        letter-spacing: normal !important;
        color: #434655 !important;


        @media (max-width: 959px) {
            font-size: 10px !important;
            text-wrap: nowrap !important;
        }
    }

    .ant-table-row {
        background-color: #ffffff !important;
        height: 27px;
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: end;
        color: #434655;
    }



    .CustomPlaceholder .ant-select-selection-item {
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #434655;

        @media (max-width: 959px) {
            font-size: 12px;
        }
    }


    .CustomPlaceholder::placeholder {
        text-align: center;
        color: #434655;
    }

    .ant-table-thead {
        ::before {
            background-color: transparent !important;

        }
    }

    .ant-table-tbody {
        tr {
            td {
                border-bottom: 0px !important;

            }
        }
    }

    .TableTotal {
        height: 27px;
        margin-top: 10px;
        margin-right: 50px;
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: end;
        color: #434655;

        @media (max-width: 959px) {
            margin-right: 20px;
            font-size: 10px;
        }
    }


    .ant-form-item {
        margin: 0px !important;
    }

}