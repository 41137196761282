@import "../../../../styles/_main.scss";

.Logo {
  margin: auto;
  color: #447ff4;
  font-size: 20px;
}


.NavBar {
  background-color: transparent;
  border: none;
  font-size: 25px;
  color: #447ff4;
}

.header {
  display: flex;
  background: #fff;
  height: toRem(80);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;


  .header-left {
    width: toRem(290);
    background-color: #c2d5f9;
    padding-top: 20px;

    p {
      color: $primary;
      font-size: 32px;
      font-weight: bold;
      margin: 0px;
    }

    img {
      width: toRem(40);
    }

    @media (max-width: 959px) {
      width: 240px;
    }
  }

  .header-center {
    padding: 0px toRem(50);
    margin: auto 0px;

    &>* {
      font-size: 32px;
      font-weight: bold;
      margin: auto 0px;
    }
  }

  .exiticon {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 959px) {
      display: none;
    }


  }


  .header-right {
    display: flex;
    margin-left: auto;
    padding: toRem(10);

    @media (max-width: 959px) {
      margin: 0px;
    }

    .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;

      input {
        border: 1px solid #eeeeee;
        padding: 10px 15px 10px 15px;
        border-radius: 19.5px;
        border-right: 0px;
        outline: none;
        color: $secondary;

        &:focus {
          border: 2px solid gray;
        }

        &:focus-visible {
          border: 2px solid gray;
        }
      }

      .input-group-text {
        margin: 5px 0px 5px -30px;
        background: #fff;
        color: $secondary;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .icon {
      color: $secondary;
      margin: auto 15px;
      border: none;
      background: white;

      @media (max-width: 959px) {
        margin: auto 10px;
      }

      svg {
        width: toRem(30);
        height: toRem(30);
      }

    }
  }

  &-Input {
    justify-content: center;
    position: relative;

    @media (max-width: 959px) {
      display: none;
    }

    &-Style {
      width: 315px;
      height: 59px;
      border-radius: 19.5px;
      border: solid 1px #eee;
      background-color: #ffffff;
      padding-left: 10px;
      padding-right: 30px;
    }

    &-SearchLogo {
      position: absolute;
      top: 15px;
      left: 280px;

      &-Style {
        width: 30px;
        height: 30px;
      }
    }
  }

}