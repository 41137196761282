.Lessons {

    &-QuestionPhoto {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        border: solid 1px #afafaf;
    }

    &-Button {

        &-DetailDelete {

            display: grid;

            &-Detail {
                width: 130px;
                height: 45px;
                border-radius: 7px;
                background-color: #45bf55;
                border: none;
                margin: 3px;
            }

            &-Delete {
                width: 130px;
                height: 45px;
                border-radius: 7px;
                background-color: #ff1818;
                border: none;
                margin: 3px;
            }

            &-Text {
                height: 21px;
                font-family: "Roboto", sans-serif;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: normal;
                text-align: center;
                color: #fafafa;
            }

        }
    }


    .ant-table-cell {
        align-items: center !important;
        text-align: center !important;
        vertical-align: middle !important;
        background-color: transparent !important;
    }

    .ant-table-row {
        background-color: #f9fafa !important;
    }


    .ant-pagination-item {
        a {
            color: #447ff4 !important;
        }

        &-active {
            background-color: #447ff4 !important;

            a {
                color: #ffffff !important;
            }
        }
    }



    .ant-pagination-item-link {
        svg {
            color: #447ff4;
        }
    }

    .ant-table-thead {
        background-color: #f9fafa !important;

        ::before {
            background-color: transparent !important;
        }
    }

    .ant-table-tbody {
        tr {
            td {
                border-bottom: 0px !important;


            }
        }
    }

}