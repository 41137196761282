html>* {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  color: $dark;
}

.text {
  &-primary {
    color: $primary;
  }

  &-secondary {
    color: $secondary;
  }
}

.w {
  &-100 {
    width: 100% !important;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.loading {
  background: #00000081;

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-small {
    background-color: #fff;
    position: initial;
    height: 400px;

  }
}