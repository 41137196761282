.Notification {
    display: flex;
    align-items: center;

    &-Area {
        background-color: #ffffff;
        width: 1003px;
        height: 532px;
        margin: 20px 522px 403px 50px;
        padding: 33px 378px 54px 10px;
        border-radius: 10px;

        &-FormText {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-NotificationTitle {
                font-family: "Nunito", sans-serif;
                font-size: 11px;
                color: #434655;
                text-align: left;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.73;
                letter-spacing: normal;
                margin: 28px 16px 18px 16px;

                @media (max-width: 959px) {
                    margin: 5px 16px;
                }

                &-Input {
                    width: 365px;
                    height: 33.4px;
                    border: solid 1px rgba(168, 170, 188, 0.2);
                    margin-left: 5px;

                    @media (max-width: 959px) {
                        width: 325px;
                    }
                }

                &-Explanation {
                    width: 110px;
                    height: 9px;
                    margin-left: 10px;
                    font-family: "Nunito", sans-serif;
                    font-size: 7px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;
                }
            }


            &-NotificationContent {
                &-Input {
                    width: 365px;
                    height: 61px;
                    border: solid 1px rgba(168, 170, 188, 0.2);
                    margin-left: 8px;
                    resize: none;
                    overflow-y: hidden;

                    @media (max-width: 959px) {
                        width: 325px;
                    }
                }

                &-Explanation {
                    width: 110px;
                    height: 9px;
                    margin-left: 10px;
                    font-family: "Nunito", sans-serif;
                    font-size: 7px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;
                }
            }

            &-NotificationPicture {
                width: 340px;
                height: 35.3px;
                border: solid 1px rgba(168, 170, 188, 0.2);
                margin-left: 17px;

                @media (max-width: 959px) {
                    margin-left: 10px;
                    width: 290px;
                }

                &-Button {
                    height: 34.2px;

                }

                &-Explanation {
                    width: 110px;
                    height: 9px;
                    margin: 0px 14px 0px 15px;
                    font-family: "Nunito", sans-serif;
                    font-size: 7px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;
                }

            }


            &-Date {
                width: 230px;
                height: 33.4px;
                padding: 7.2px 4px 7.2px 2px;
                border: solid 1px rgba(168, 170, 188, 0.2);
                background-color: #ffffff;

                &-Or {

                    width: 30px;
                    height: 11px;
                    margin: 52px 10px 15px 10px;
                    font-family: "Nunito", sans-serif;
                    font-size: 8px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;
                }



                &-Explanation {
                    width: 110px;
                    height: 9px;
                    margin: 17px 17px 0px 137px;
                    font-family: "Nunito", sans-serif;
                    font-size: 8px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;

                    @media (max-width: 959px) {
                        margin: 0px
                    }


                }

            }

            &-DateButton {

                &-Style {
                    width: 101px;
                    height: 35px;
                    border-radius: 10px;
                    margin-left: 65px;
                    border-color: transparent;
                    background-color: #447ff4;
                    color: #ffffff;
                    font-size: 8px;
                    font-family: "Nunito", sans-serif;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.38;
                    letter-spacing: normal;
                    text-decoration: none;

                    @media (max-width: 959px) {
                        margin-bottom: 10px;
                        margin-left: 0px;
                        width: 200px;
                    }

                }
            }

            &-SentButton {
                width: 101px;
                height: 34px;
                border-radius: 10px;
                margin-left: 393px;
                border-color: transparent;
                background-color: #447ff4;
                color: #ffffff;
                font-size: 8px;
                font-family: "Nunito", sans-serif;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.38;
                letter-spacing: normal;
                text-decoration: none;

                @media (max-width: 959px) {
                    margin-left: 0px;
                }

            }

        }


    }

    .ant-form-item {
        @media (max-width: 959px) {
            margin-bottom: 0px;
        }
    }

    .ant-upload-select {
        @media (max-width: 959px) {
            display: inline-block;
        }
    }


}