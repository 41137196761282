.Students {


    &-Input {
        position: relative;

        &-Style {
            width: 315px;
            height: 59px;
            border-radius: 19.5px;
            border: solid 1px #eee;
            background-color: #ffffff;
            padding-left: 10px;
            padding-right: 30px;
            margin-bottom: 16px;
        }

        &-SearchLogo {
            position: absolute;
            top: 15px;
            left: 280px;

            &-Style {
                width: 30px;
                height: 30px;
            }
        }
    }

    &-Photo {
        width: 50px;
        height: 50px;
        border-radius: 16px;
    }

    &-DetailButton {
        width: 215px;
        height: 54px;
        border-radius: 16px;
        background-color: #447ff4;
        text-decoration: none;
        border: none;



        &-Text {
            font-family: "Nunito", sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
        }
    }

    .ant-select-selector {
        color: #447ff4 !important;
        border: 1px solid #d9d9d9 !important;
    }

    .ant-select-selection-item {
        color: #447ff4 !important;
    }

    .ant-table-cell {
        align-items: center !important;
        text-align: center !important;
        vertical-align: middle !important;
        background-color: transparent !important;
    }

    .ant-table-row {
        background-color: #f9fafa !important;
    }


    .ant-pagination-item {
        a {
            color: #447ff4 !important;
        }

        &-active {
            background-color: #447ff4 !important;

            a {
                color: #ffffff !important;
            }
        }
    }



    .ant-pagination-item-link {
        svg {
            color: #447ff4;
        }
    }

    .ant-table-thead {
        ::before {
            background-color: transparent !important;
        }
    }

    .ant-table-tbody {
        tr {
            td {
                border-bottom: 0px !important;

            }
        }
    }
}