.Dashboard {
    display: flex;
    justify-content: space-between;

    @media (max-width: 959px) {
        display: flex;
        flex-wrap: wrap;
    }


    .g2-html-annotation {
        top: 63px !important;
    }

    .RecentlySoldPackages {
        display: flex;
        flex-wrap: wrap;

    }

    &-LeftArea {
        width: 710px;
        display: flex;
        flex-wrap: wrap;
        margin: 10px;
        align-content: flex-start;

        &-DashboardCard {

            &-Style {
                height: auto;
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                flex-wrap: wrap;

            }

            &-Card {
                &-1 {
                    width: 300px;
                    height: 172px;
                    border-radius: 16px;
                    background-color: #fe69b5;
                    margin-right: 30px;
                    margin-bottom: 30px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    @media (max-width: 959px) {
                        width: 300px;
                    }

                    &-Icon {
                        width: 55px;
                        object-fit: contain;
                        color: #ffffff;
                        position: absolute;
                        margin-left: 230px;
                    }

                    &-Number {
                        font-family: "Nunito", sans-serif;
                        font-size: 32px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.34;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                        margin: 7px 0px;
                    }

                    &-BigTitle {
                        font-family: "Nunito", sans-serif;
                        font-size: 24px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 30px;
                    }
                }

                &-2 {
                    width: 300px;
                    height: 172px;
                    border-radius: 16px;
                    background-color: #447ff4;
                    margin-bottom: 30px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    @media (max-width: 959px) {
                        width: 300px;
                    }

                    &-Icon {
                        width: 55px;
                        object-fit: contain;
                        color: #ffffff;
                        position: absolute;
                        margin-left: 230px;
                    }

                    &-Number {
                        font-family: "Nunito", sans-serif;
                        font-size: 32px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.34;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                        margin: 7px 0px;
                    }

                    &-BigTitle {
                        font-family: "Nunito", sans-serif;
                        font-size: 24px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 30px;
                    }
                }

                &-3 {
                    width: 300px;
                    height: 172px;
                    border-radius: 16px;
                    background-color: #ffffff;
                    margin-right: 30px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    @media (max-width: 959px) {
                        width: 300px;
                    }

                    &-Icon {
                        width: 55px;
                        object-fit: contain;
                        color: #ffffff;
                        position: absolute;
                        margin-left: 230px;

                    }

                    &-Number {
                        font-family: "Nunito", sans-serif;
                        font-size: 32px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.34;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-BigTitle {
                        font-family: "Nunito", sans-serif;
                        font-size: 24px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }
                }

                &-4 {
                    width: 300px;
                    height: 172px;
                    border-radius: 16px;
                    background-color: #ffffff;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    @media (max-width: 959px) {
                        width: 300px;
                    }

                    &-Icon {
                        width: 55px;
                        object-fit: contain;
                        color: #ffffff;
                        position: absolute;
                        margin-left: 230px;
                    }

                    &-Number {
                        font-family: "Nunito", sans-serif;
                        font-size: 32px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.34;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-BigTitle {
                        font-family: "Nunito", sans-serif;
                        font-size: 24px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }
                }
            }
        }


        &-ActiveUsers {
            padding: 20px;
            width: 100%;
            background-color: #fff;
            margin: 20px 0px;
            border-radius: 16px;

            @media (max-width: 959px) {
                display: grid;
                width: 300px;
            }

            &-GraficArea {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: start;
                margin-bottom: 20px;

                @media (max-width: 959px) {
                    display: grid;
                }

                &-ActiveStudents {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;


                    &-Grafic {
                        width: 136px;
                        height: 136px;

                        @media (max-width: 959px) {
                            margin-bottom: 30px;
                        }
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: center;
                        color: #a8aabc;
                    }
                }


                &-InactiveStudents {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;


                    @media (max-width: 959px) {
                        width: 300px;
                        height: auto;
                    }

                    &-Grafic {
                        width: 136px;
                        height: 136px;

                        @media (max-width: 959px) {
                            margin-bottom: 30px;
                        }
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: center;
                        color: #a8aabc;
                    }
                }

                &-ActiveTeachers {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    &-Grafic {
                        width: 136px;
                        height: 136px;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: center;
                        color: #a8aabc;
                    }
                }
            }

            &-Title {
                font-family: "Nunito", sans-serif;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
            }
        }

        &-QuestionsAsked {
            padding: 20px;
            width: 100%;
            background-color: #fff;
            border-radius: 16px;

            @media (max-width: 959px) {
                width: 300px;
                height: auto;
            }

            &-Title {
                font-family: "Nunito", sans-serif;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
            }

            &-Information {
                display: flex;
                align-items: center;
                margin: 20px 0px;

                &-Student {
                    display: flex;
                    align-items: center;
                    margin-right: 130px;

                    &-Icon {
                        width: 52px;
                        height: 52px;
                        background-color: #f4f444;
                        border-radius: 100px;
                    }

                    &-Text {
                        display: grid;
                        padding-left: 20px;


                        &-Title {
                            font-family: "Nunito", sans-serif;
                            font-size: 12px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #a8aabc;
                        }

                        &-Number {
                            font-family: "Nunito", sans-serif;
                            font-size: 24px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #434655;
                        }
                    }
                }
            }
        }
    }



    &-RightArea {
        width: 710px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;


        &-Graphic {
            width: 100%;
            height: auto;
            background-color: #fff;
            padding: 20px;
            margin-bottom: 20px;
            border-radius: 16px;

            @media (max-width: 959px) {
                width: 300px;
                height: auto;
                margin-left: 10px;
            }

            &-Information {
                display: flex;
                align-items: center;
                margin: 20px 0px;
                flex-wrap: wrap;

                @media (max-width: 959px) {
                    display: flex;
                }

                &-selected {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    &-button {

                        padding: 5px 10px;
                        border: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background-color: transparent;

                        span {
                            color: #717579;
                            font-size: 16px;
                            font-weight: bold;
                        }

                        &.active {

                            svg {
                                color: #447ff4;
                            }
                        }

                        svg {
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                        }

                        &:first-child {
                            margin-right: 15px;
                        }
                    }
                }

                &-Student {
                    display: flex;
                    align-items: center;
                    margin-right: 130px;

                    @media (max-width: 959px) {
                        margin-bottom: 5px;
                    }

                    &-Icon {
                        width: 52px;
                        height: 52px;
                        background-color: #447ff4;
                        border-radius: 100px;
                    }

                    &-Text {
                        display: grid;
                        padding-left: 20px;


                        &-Title {
                            font-family: "Nunito", sans-serif;
                            font-size: 12px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #a8aabc;
                        }

                        &-Number {
                            font-family: "Nunito", sans-serif;
                            font-size: 24px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #434655;
                        }
                    }
                }

                &-Teacher {
                    display: flex;
                    align-items: center;

                    &-Icon {
                        width: 52px;
                        height: 52px;
                        background-color: #fe69b5;
                        border-radius: 100px;
                    }

                    &-Text {
                        display: grid;
                        padding-left: 20px;

                        &-Title {
                            font-family: "Nunito", sans-serif;
                            font-size: 12px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #a8aabc;
                        }

                        &-Number {
                            font-family: "Nunito", sans-serif;
                            font-size: 24px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #434655;
                        }
                    }
                }
            }
        }

        &-Lists {
            width: 100%;
            height: 806px;
            display: flex;

            @media (max-width: 959px) {
                display: flex;
                flex-wrap: wrap;
            }


            &-PaymentRequests {
                width: 100%;
                border-radius: 16px;
                background-color: #fff;
                padding: 20px;
                margin-right: 10px;
                display: flex;
                flex-wrap: wrap;

                @media (max-width: 959px) {
                    margin-bottom: 20px;
                }


                @media (max-width: 959px) {
                    display: flex;
                    flex-wrap: wrap;
                }


                .ant-table-row-level-0 {
                    height: 70px !important;

                }

                .ant-table-cell {
                    padding-bottom: 0px !important;
                    padding-top: 0px !important;

                }

                &-Title {
                    margin: 0px;

                    &-Text {
                        font-family: "Nunito", sans-serif;
                        font-size: 19px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.37;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                        margin: 0px;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: left;
                        color: #a8aabc;
                        margin: 0px;
                    }
                }


                &-Text {

                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #434655;
                    margin: 0px;
                }

                &-MoreArea {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    align-items: center;


                    &-More {
                        border-radius: 16px;
                        background-color: #c2d5f9;
                        text-decoration: none;
                        border: none;
                        padding: 20px 70px;


                        &-Text {
                            font-family: "Nunito", sans-serif;
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.38;
                            letter-spacing: normal;
                            text-align: left;
                            color: #447ff4;
                        }
                    }
                }

                &-Price {
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: right;
                    color: #434655;
                }
            }


            &-RecentlySoldPackages {
                width: 100%;
                border-radius: 16px;
                background-color: #fff;
                padding: 20px;
                margin-right: 10px;
                display: flex;
                flex-wrap: wrap;



                @media (max-width: 959px) {
                    display: flex;
                    flex-wrap: wrap;
                }

                &-Icon {
                    height: 48px;
                    width: 48px;
                }

                .ant-table-row-level-0 {
                    height: 70px !important;

                }

                .ant-table-cell {
                    padding: 0px !important;

                }

                &-Title {
                    margin: 0px;

                    &-Text {
                        font-family: "Nunito", sans-serif;
                        font-size: 19px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.37;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                        margin: 0px;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: left;
                        color: #a8aabc;
                        margin: 0px;



                        &-Small {
                            font-family: "Nunito", sans-serif;
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.38;
                            letter-spacing: normal;
                            text-align: left;
                            color: #a8aabc;
                            width: 100%;
                            margin-top: 0px;
                            margin-left: 15px;
                        }
                    }

                }

                &-Icon {
                    color: #447ff4;
                }

                &-Text {

                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #434655;
                    margin: 0px;
                    margin-left: 15px;
                }


                &-MoreArea {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    align-items: center;

                    &-More {
                        border-radius: 16px;
                        background-color: #c2d5f9;
                        text-decoration: none;
                        border: none;
                        padding: 20px 70px;

                        &-Text {
                            font-family: "Nunito", sans-serif;
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.38;
                            letter-spacing: normal;
                            text-align: left;
                            color: #447ff4;
                        }
                    }
                }

                &-Price {
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: right;
                    color: #434655;
                }
            }
        }
    }

    .ant-table-row {
        background-color: #ffffff !important;
    }

    .ant-table-wrapper {
        @media (max-width: 959px) {
            margin-top: 30px;
        }
    }

}