.SellPacket {

    &-FirstArea {
        display: flex;
        flex-wrap: wrap;

        &-IconSelect {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;



            @media (max-width: 959px) {
                justify-content: left;
                margin-left: 50px;
            }

            &-Icon {
                width: 40px;
                height: 40px;
                color: #a8aabc;
                margin-bottom: 5px;
                margin-right: 20px;

            }

            &-Select {
                width: 222px;
                height: 56px;
                border-radius: 10px;
                border: solid 2px #a8aabc;

            }
        }
    }

    &-SecondArea {

        &-List {

            &-Button {

                &-DetailButton {
                    width: 130px;
                    height: 45px;
                    border-radius: 7px;
                    background-color: #06f;
                    border: none;

                    &-Text {
                        height: 19px;
                        font-family: "Roboto", sans-serif;
                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fafafa;
                    }
                }
            }
        }

    }


    .ant-form-item {
        margin: 0px !important;
    }

    .ant-table-cell {
        background-color: #f9fafa !important;
        align-items: center !important;
        text-align: center !important;
        vertical-align: middle !important;
        background-color: transparent !important;
        font-family: "Nunito", sans-serif !important;
        font-size: 18px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.35 !important;
        letter-spacing: normal !important;
        color: #434655 !important;
    }

    .ant-table-row {
        background-color: #f9fafa !important;
        height: 11px !important;
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #434655 !important;
    }



    .CustomPlaceholder .ant-select-selection-item {
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #a8aabc;
    }

    .CustomPlaceholder::placeholder {
        text-align: center;
        color: #434655;
    }

    .ant-table-thead {
        ::before {
            background-color: transparent !important;

        }

        background-color: #f9fafa !important
    }



    .ant-table-tbody {
        tr {
            td {
                border-bottom: 0px !important;
                color: #434655 !important;
                font-size: 18px !important;
                font-weight: normal !important;

            }
        }
    }

    &-TotalAmount {
        text-align: right;

        &-Message {
            font-family: "Nunito", sans-serif;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            margin-right: 50px;
            color: #434655;
        }
    }
}