.QuestionsDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-FirstArea {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;

        @media (max-width: 959px) {
            width: 100%;
            height: 100%;
        }


        &-Style {
            width: 565px;
            height: 375px;
            margin: 20px 10px 7px 50px;
            padding: 39px 152px 20px 19px;
            border-radius: 24px;
            background-color: #ffffff;

            @media (max-width: 959px) {
                width: 100%;
                height: 100%;
                margin: 0px;
                padding: 10px;
            }
        }

        &-Text {
            font-family: "Nunito", sans-serif;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #434655;
            margin: 10px;

            @media (max-width: 959px) {
                font-size: 14px;
            }
        }


        &-Annotation {
            padding: 20px 152px 20px 0px;

            @media (max-width: 959px) {
                padding: 0px;
            }

            &-AnnotationTitle {
                font-family: "Nunito", sans-serif;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #434655;

                @media (max-width: 959px) {
                    font-size: 18px;
                    margin: 20px 0 0 0;
                }
            }

            &-MessageTitle {
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: left;
                color: #023358;

                @media (max-width: 959px) {
                    font-size: 14px;
                    margin: 0px;
                }
            }

            &-Message {
                font-family: Roboto;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: left;
                color: #a2a1bc;
                margin-bottom: 10px;

                @media (max-width: 959px) {
                    font-size: 11px;
                    margin: 0px;
                }
            }


        }
    }

    &-SecondArea {

        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: 959px) {
            flex-wrap: wrap;
            flex-direction: column;
        }

        &-Text {
            height: 32px;
            margin: 7px 530px 0px 76px;
            font-family: "Nunito", sans-serif;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: center;
            color: #434655;

            @media (max-width: 959px) {
                margin: 0px;
                font-size: 14px;
                text-align: left;
                margin-top: 20px;
            }
        }

        &-Question {
            width: 542px;
            height: 362px;
            margin: 15px 49px 144px 73px;
            border-radius: 20px;
            border: solid 1px #707070;

            @media (max-width: 959px) {
                width: 100%;
                height: 100%;
                margin: 0px;
            }
        }

        &-Answer {
            width: 542px;
            height: 362px;
            margin: 15px 364px 144px 54px;
            border-radius: 20px;
            border: solid 1px #707070;

            @media (max-width: 959px) {
                width: 100%;
                height: 100%;
                margin: 0px;
            }

        }
    }

}