@import "../../styles/_main.scss";
.auth {
  margin: 0;
  background-image: url("../../../public/Assets/images/auth_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .mbt-3 {
    margin-top: toRem(10);
    margin-bottom: toRem(10);
  }
  .back-color {
    height: 100vh;
    width: 100vw;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 0;
    .form-div {
      background-color: #fff;
      border-radius: 10px;
      padding: 78px 66px;
      width: 540px;
      h3 {
        font-size: 48px;
        font-weight: 900;
        margin: 0px;
      }
      h4 {
        font-size: 32px;
        font-weight: bold;
        margin: 0px;
      }
      .form {
        &-input {
          label {
            font-size: 14px;
            font-weight: normal;
            color: $secondary;
          }

          &-group {
            position: relative;
            color: $secondary;
            display: flex;

            &-left {
              position: absolute;
              font-size: 24px;
              top: 13px;
              left: 15px;
            }

            &-right {
              position: absolute;
              font-size: 24px;
              top: 13px;
              right: 20px;
            }

            input {
              color: $secondary;
              font-size: 14px;
              padding: 15px 50px;
              border-radius: 7px;
              border: solid 2px rgba(68, 127, 244, 0.15);
              outline: none;
              width: 100%;
              &:focus {
                border: solid 2px $secondary;
              }
            }
          }
          &-text {
            &-error {
              color: #fd5353;
            }
          }
        }
      }
    }
  }
}
